import { FC, useMemo } from 'react';
import DateUtils from '../../../../utils/DateUtils';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';

const DatePreview: FC<ActionPreviewBaseProps<string>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;

  const date = useMemo(() => response && new Date(response), [response]);
  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);

  if (answerOnly) {
    return <>{date ? DateUtils.formatDate(date) : '-'}</>;
  }

  return <ActionPreview data-cy="date-preview" question={title} description={previewDescription} answer={date ? DateUtils.formatDate(date) : '-'} />;
};

export default DatePreview;
